@import url('https://fonts.googleapis.com/css?family=Ubuntu');

body{
  background-image: url("./pixel-black.png");
  background-color: #000000;
  background-repeat: repeat;
  color: #ffffff;
  font-family: Ubuntu, sans-serif;
  a, a:hover {
    text-decoration: none;
    color: #ffffff;
  }
  h1, h2{
    font-weight: lighter;
    font-family: Ubuntu, sans-serif;
  }
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 22px;
  }
}

$break: 920px;

div.App{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;


  header {
    position: relative;
    width: 100%;
    height: 56vw;

    @media (max-width: 1500px) {
      height: 672px;
    }

    @media (min-width: 920px) {
      height: 840px;
    }

    div.video {
      position: absolute;
      top: 0;
      bottom: 3px;
      left: 0;
      right: 0;
      z-index: 0;
      overflow: hidden;
      visibility: visible;
      background-image: url("./video.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      text-align: center;
      video{
        min-height: 100%;
        min-width: 100%;
        width: auto;
        max-height: 200%;
        max-width: 200%;
        @media (max-width: 600px) {
          max-width: 500%;
        }
        visibility: visible;
      }
    }

    div.content {
      visibility: visible;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-image: url("./masque-header.png");
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;
      padding-top: 20px;
      @media (max-width: 600px) {
        max-width: 500%;
      }

      .logo {
        margin-top: 132px;
        margin-left: 20px;
        margin-right: 20px;
      }

      .text{
        margin-top: 142px;
        width: 470px;
        margin-right: 75px;
        .littleLine{
          margin: 27px 0;
        }
        h2{
          margin-top: 50px;
          margin-bottom: 0;
        }
        h1{
          margin-top: 3px;
          margin-bottom: 0;
        }
      }

      @media (max-width: $break) {
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: start;
        align-items: flex-start;

        .logo {
          margin: 0 0 0 50px;
          width: 166px;
          height: 145px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          margin: 10px 50px;
          @media (max-width: 420px) {
            margin-top: -2em;
          }
          width: auto;
          .littleLine{
            margin: 27px 0;
          }
          h2{
            margin-top: 50px;
            margin-bottom: 0;
          }
          h1{
            margin-top: 3px;
            margin-bottom: 0;
          }
        }
      }

    }
  }

  p{
    margin-top: 0;
    letter-spacing: 1px;
    line-height: 23px;
    text-align: left;
    font-size: 16px;
  }

  div.bodyContent {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    width: 920px;
    margin: -140px auto 0 auto;
    .distribGif{
      margin-bottom: 1em;
    }

    @media (max-width: $break) {
      width: 100%;
      margin: -50px 0 0 0;
    }

    .games, .distributor {
      width: 100%;
      .text {
        margin-left: 100px;
        width: 370px;
        @media (max-width: 920px) {
          margin-left: 0;
          width: auto;
          padding: 0 50px;
        }
        z-index: 1;
        h1 {
          margin-bottom: 0;
        }
        .littleLine{
          margin: 27px 0;
        }
        img{
          margin-top: 20px;
          z-index: 0;
          max-width: 80%;
        }
      }
      .brands {
        overflow: hidden;
        margin-top: -185px;
        z-index: 10;
        width: 950px;
        height: auto;
        margin-left: -40px;
        @media (max-width: $break) {
          width: 100%;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0;
        }
        svg{
          width: 100%;
          .growMe {
            transition: all .2s ease-in-out;
            &:hover.brand{
              transform: translate(-3px, -3px) scale(1.01);
            }
            &:hover.game{
              transform: translate(-5px, -3px) scale(1.01);
            }
          }
          .over {
            transition: all .2s ease-in-out;
            opacity: 0;
            &:hover{
              opacity: 0.9;
              cursor: pointer;
            }
          }
        }
      }



    }

    .distributor {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: space-between;
      @media (max-width: 920px) {
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: center;
      }

      .text {
        margin-top: 15px;
        margin-right: 30px;
      }
      .distributors {
        flex-grow: 1;
        margin: 0 2em;
        max-width: 344px;
        text-align: center;
        @media (max-width: $break) {
          width: 100%;
          max-width: 340px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 0;
        }
        svg{
          display: inline;

          .over {
            transition: all .2s ease-in-out;
            opacity: 0;
            &:hover{
              opacity: 0.9;
              cursor: pointer;
            }
          }
        }
        .visible {
          visibility: visible;
          opacity: 1;
          transition: visibility 0.5s, opacity 0.5s linear;
        }
        .hide{
          visibility: hidden;
          opacity: 0;
          transition: visibility 0.5s, opacity 0.5s linear;
        }
      }
    }
  }

  footer {
    .headImage {
      content: ' ';
      height: 345px;
      width: 100%;
      img{
        width: 100%;
        height: 100%;
      }

      @media (max-width: 2500px) {
        background-image: url("./footer.png");
        background-size: cover;
        background-position-x: center;
        background-position-y: bottom;
        img{
          display: none;
        }
      }
      @media (min-width: 2449px) {
        height: 380px;
      }
    }
    .background {
      background-image: url("./pixel-blue.png");
      padding-bottom: 0;
    }
    .content {
      margin-top: -50px;
      width: 920px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      @media (max-width: $break) {
        width: 100%;
        flex-direction: column;
      }


      .text {
        margin-left: 100px;
        margin-right: 100px;
        width: 370px;
        @media (max-width: 920px) {
          margin-left: 0;
          margin-right: 0;
          width: auto;
          padding: 0 50px;
        }
        z-index: 1;
        h1 {
          margin-bottom: 0;
        }
        .littleLine{
          margin: 27px 0;
        }
        .logo {
          text-align: center;
          @media (max-width: $break) {
            text-align: left;
          }
          margin-top: 2em;
        }
      }

      .contactForm {
        flex-grow: 1;
        @media (max-width: $break) {
          width: 80%;
          margin: 1em auto 0 auto;
          flex-direction: column;
        }
      }
      form{
        input[type=text], input[type=email], textarea{
          display: block;
          border: solid 1px #2778C9;
          margin-bottom: 1em;
          background: #134679;
          padding: 0 20px;
          margin: 1em -20px 0  -20px;
          min-height: 50px;
          width: 100%;
          color: #ffffff;
          outline: none;
          &::placeholder{
            color: #d3d3d3;
          }
          &:focus{
            box-shadow: 0 0 5px #2778C9;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #eee;
        }

        input[type=checkbox] {
          border: solid 1px #2778C9;
          margin-bottom: 1em;
          background: #134679;
          padding: 0 20px;
        }
        textarea{
          padding-top: 20px;
        }
        .rgpd {
          margin-top: 2em;
        }
        .endForm {
          margin-top: 2em;
          margin-bottom: 1em;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          @media (max-width: 920px) {
            flex-direction: column-reverse;
            justify-content: stretch;
            align-items: stretch;
            .textLeg{
              text-align: right;
              margin: 0 0 1em 0;
            }
          }

          button{
            background: #212121;
            border-radius: 0;
            border: none;
            color: #ffffff;
            height: 66px;
            font-size: 16px;
            padding: 15px;
            text-transform: uppercase;
            img{
              vertical-align: middle;
              margin: 0.5em;
            }
          }
          .text {
            //width: 150px;
            vertical-align: middle;
            margin: 0;
          }
        }

      }
    }
    .footerBar {
      margin-top: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      > img{
        background: #212121;
        &:last-of-type {
          transform: scaleX(-1);
        }
        @media (max-width: $break) {
          display: none;
        }
      }
      .links {
        background: #212121;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        width: 920px;
        @media (max-width: $break) {
          width: 100%;
        }
        padding: 10px;
        height: 80px;
        > * {
          height: 80px;
          line-height: 80px;
          padding: 0 20px;
          &:hover {
            cursor: pointer;
            color: #eeeeee;
          }
        }
      }
    }
  }
}

.grecaptcha-badge{
  visibility: collapse !important;
}
